import './Globals.css';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import GameAWeek from './pages/Events/GameAWeek';
import PixelJam from './pages/Events/PixelJam2024';
import Linktree from './pages/Linktree/Linktree';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Navbar />}> */}
          <Route index element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/game-a-week2024" element={<GameAWeek/>}/>
          <Route path="/pixeljam2024" element={<PixelJam/>}/>
          <Route path="/links" element={<Linktree/>}/>
          {/* <Route path="events" element={<Events />} /> */}
          {/* <Route index element={<Events />} /> */}
          {/* <Route path="*" element={<Home />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;