import './EventPage.css'

import shape5 from '../images/shapes/shape5.webp';

const yellowTheme = {
    backgroundColor: "#f8a348",
    shape: shape5
};

const themes = {
    "yellow": yellowTheme
};

function EventPage(props) {
    const t = themes[props.theme];
    return (
        <div id='main'>
            <img src={t.shape} className='topCornerShape' />
            <div id='content'>
                <div id='textContent'>
                    <h1>{props.title}</h1>
                    {props.date ? <h2>{props.date}</h2> : <></>}
                    <p className='description'>{props.desc}</p>
                </div>

                {props.content1 ?
                    <div id='topLeftContent'>
                        {props.content1}
                    </div>
                    : <></>
                }

                {props.content2 ?
                    <div id='bottomLeftContent'>
                        {props.content2}
                    </div>
                    : <></>
                }

                {props.content3 ?
                    <div id='bottomRightContent'>
                        {props.content3}
                    </div>
                    : <></>
                }
            </div>
        </div>
    )
}

export default EventPage;