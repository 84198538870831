import './BlurredImage.css';

function BlurredImage(props) {
    return (
        <div className='fullRelative'>
            <div className='imageStack' style={{
                backgroundImage: `url('${props.image}')`,
                backgroundSize: 'cover',
                filter: 'blur(8px)',
                width: 'calc(100% + 32px)',
                height: 'calc(100% + 32px)',
                margin: '-16px'
            }}></div>
            <div className='imageStack' style={{
                backgroundImage: `url('${props.image}')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            }}>
            </div>

        </div>
    )
}

export default BlurredImage;