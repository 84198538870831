import '../Globals.css';
import '../App.css';
import './Home.css';
import BlurredImage from '../components/BlurredImage';
import HomeTile from '../components/HomeTile';

import TextLogo from '../images/textlogo.webp';
import TopShape from '../images/shapes/shape1.webp';
import MiddleShape from '../images/shapes/shape2.webp';
import BottomShape from '../images/shapes/shape3.webp';

import ComingSoonBlue from '../images/tiles/comingsoonblue.webp';
import ComingSoonPink from '../images/tiles/comingsoonpink.webp';
import ComingSoonPurple from '../images/tiles/comingsoonpurple.webp';
import GameAWeekIcon from '../images/gameaweek/gawsyd_hr.webp';
import PixelJamIcon from '../images/pixeljam2024/Itchbanner.webp';
import DiscordIcon from '../images/DiscordIcon.webp';
import Constants from '../Constants';

function Home() {
    return (
        <>
            <div id="mainGridContainer">
                <div id='mainPage'>
                    <div id='mainBackground'>
                        <img id='middleShape' className='shape' src={MiddleShape} alt=''/>
                        <img id='bottomShape' className='shape' src={BottomShape} alt=''/>

                        <div id='mainContent'>
                            <div id='header'>
                                <div id='logoContainer'>
                                    <img id='topShape' src={TopShape} alt=''/>
                                    <img id='textLogo' src={TextLogo} alt=''/>
                                </div>
                            </div>


                            <div id='bodyContent'>
                                <p className='bodyText'>
                                    We are Pixel Pasture, an indie game dev studio and social host collective.
                                    Formed in Sydney, Australia 2023.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='scrollContainer' id='column1'>
                    <HomeTile link='/game-a-week2024' image={GameAWeekIcon} title="GAME A WEEK"/>
                    <HomeTile link='/pixeljam2024' image={PixelJamIcon} title="PIXEL JAM"/>
                    <HomeTile link={Constants.DiscordLink} newtab image={DiscordIcon} title="DISCORD"/>
                    </div>
                <div className='scrollContainer' id='column2'>
                    <BlurredImage image={ComingSoonPink}></BlurredImage>
                    <BlurredImage image={ComingSoonPurple}></BlurredImage>
                    <BlurredImage image={ComingSoonBlue}></BlurredImage>
                </div>
            </div>
        </>
    );
}

export default Home;