import EventPage from "../../components/EventPage";
import './PixelJam2024.css';

import BlurredImage from '../../components/BlurredImage';
import PixelJamIcon from '../../images/pixeljam2024/Itchbanner.webp';

import JaycarLogo from '../../images/pixeljam2024/sponsors/jaycar_icon.webp';
import TPILogo from '../../images/pixeljam2024/sponsors/tpi.webp';

import BentoLogo from '../../images/pixeljam2024/winners/bento.png';
import TamaLogo from '../../images/pixeljam2024/winners/tama.png';
import SMPLogo from '../../images/pixeljam2024/winners/smp.png';

function PixelJam() {
    return (
        <EventPage
            title="Pixel Jam 2024"
            desc='Pixel Jam 2024 invites individuals from diverse backgrounds, including seasoned developers, students, and curious enthusiasts, to converge in an Australia-wide game jam. 
                Join forces with a team of any size for an enjoyable weekend dedicated to game development.'
            date="Janurary 13 - Janurary 15 2024"
            theme="yellow"

            content1={
                <BlurredImage image={PixelJamIcon}/>
            }

            content2={
                <section id="sponsorsSection">
                    <div className="subContentTitle">SPONSORS AND PARTNERS</div>
                    <div className='companies'>
                        <a href='https://jaycar.com.au' target='_blank' rel='noreferrer' className='companyLink'>
                            <img src={JaycarLogo} className='companyIcon' alt='Jaycar Logo' draggable={false}></img>
                        </a>
                        <a href='https://bloodontheclocktower.com/about-us' target='_blank' rel='noreferrer' className='companyLink'>
                            <img src={TPILogo} className='companyIcon' alt='The Pandemonium Institute Logo' draggable={false}></img>
                        </a>
                    </div>
                </section>
            }

            content3={<section id="winnersSection">
                <div className="subContentTitle">WINNERS</div>
                <div className='winners'>
                    <div className='winner'>
                        <a href='https://antisaic.itch.io/bento-bumble' target='_blank' rel='noreferrer' className='winnerLink'>
                            <div className='winnerTitle'>BENTO BUMBLE</div>
                            <div className='winnerTeam'>KISSED BY MARIO</div>
                            <div className='winnerImage'>
                                <img src={BentoLogo} className='winnerIcon' alt='Bento Bumble' draggable={false}></img>
                            </div>
                        </a>
                    </div>
                    <div className='winner'>
                        <a href='https://ayielin.itch.io/pixel-jam-2024-test' target='_blank' rel='noreferrer' className='winnerLink'>
                            <div className='winnerTitle'>TAMA ご FAST!</div>
                            <div className='winnerTeam'>TEAM YIPPEE</div>
                            <div className='winnerImage'>
                                <img src={TamaLogo} className='winnerIcon' alt='Tama Go Fast!' draggable={false}></img>
                            </div>
                        </a>
                    </div>
                    <div className='winner'>
                        <a href='https://clevertop.itch.io/smoothie-maker-pro' target='_blank' rel='noreferrer' className='winnerLink'>
                            <div className='winnerTitle'>SMOOTHIE MAKER PRO</div>
                            <div className='winnerTeam'>TEAM STINGRAY</div>

                            <div className='winnerImage'>
                                <img src={SMPLogo} className='winnerIcon' alt='Smoothie Maker Pro' draggable={false}></img>
                            </div>
                        </a>
                    </div>
                </div>
            </section>}
        />
    )
}

export default PixelJam;