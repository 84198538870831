import EventPage from "../../components/EventPage";
import './GameAWeek.css';

import BlurredImage from '../../components/BlurredImage';

import gaw from '../../images/gameaweek/gawsyd_hr.webp';
import gawMelb1 from '../../images/gameaweek/gaw-melb1.webp';
import gawMelb2 from '../../images/gameaweek/gaw-melb2.webp';

function GameAWeek() {
    return (
        <EventPage
            title="Game A Week"
            desc="Game-a-Week is an intensive program in which participants will create 5 prototype games — one game each week. 
                    The aim of this program is to get comfortable with the practice of rapid prototyping: working quickly to create a small playable game that effectively proves or disproves a design concept. 
                    Drawing influence from game jams, the program will prompt you with weekly thematic, aesthetic, or mechanical constraints (e.g. “time” or “black-and-white” or “one-button input”). 
                    This iteration of the Game-a-Week concept is adapted from the course concept originally developed by Douglas Wilson and Bennett Foddy"
            date="June 26 - July 24"
            theme="yellow"
            
            content1={<BlurredImage image={gaw} />}
            content2={<BlurredImage image={gawMelb2} /> }
            content3={<BlurredImage image={gawMelb1} />}
        />
    )
}

export default GameAWeek;