import './LinktreeLink.css';

export default function LinktreeLink({ name, link, icon }) {
    return (
        <a className="linkContainer" href={link} target="_blank" rel="noreferrer">
            {icon ? <img className="linkIcon" src={icon} alt={`${name} icon`}></img> : <></>}   
            <div className='linkName'>
                {name}
            </div>
        </a>
    );
}