import './HomeTile.css';
import BlurredImage from '../components/BlurredImage';

function HomeTile(props) {
    return (
        <div style={{ position: 'relative' }}>
            {props.link ?
                <>
                    <a href={props.link} className='tile' target={props.newtab ? '_blank' : '_self'}>
                        <div className='titleText'>{props.title}</div>
                        <BlurredImage image={props.image}></BlurredImage>
                    </a>
                </>
                :
                <BlurredImage image={props.image}></BlurredImage>
            }

        </div>
    )
}

export default HomeTile;